import React, { useState } from 'react';
import { useTheme, alpha } from '@material-ui/core';
import Link from 'next/link';
import { ITheme } from '../../../common/theme';
import Box from '../Box';
import { MenuItem } from '../AppBarDesktop';
import AppBarDrawer from '../AppBarDrawer';
import { Logo, ChevronDown } from '../Icons';
import IconButton from '../IconButton';
import useStyles from './useStyles';

interface AppBarMobileProps {
  menuItems: MenuItem[];
  letsConnectCard: React.ReactElement | null;
  // eslint-disable-next-line react/require-default-props
  lightTheme?: boolean;
}

const AppBarMobile: React.FC<AppBarMobileProps> = ({
  menuItems,
  letsConnectCard = null,
  lightTheme = false,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme<ITheme>();
  const classes = useStyles({
    lightTheme,
  });

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleMenuIconClick = () => {
    setIsDrawerOpen((open) => !open);
  };

  const menuIconColor = lightTheme
    ? alpha(theme.palette.grey[900], 0.5)
    : alpha(theme.palette.common.white, 0.8);

  const burgerTopLineClassName = isDrawerOpen
    ? classes.topLineOpened
    : classes.topLineClosed;
  const burgerBottomLineClassName = isDrawerOpen
    ? classes.bottomLineOpened
    : classes.bottomLineClosed;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className="w-full"
    >
      <Box pt={1} display="flex" alignItems="center">
        <Link
          href="/"
          className={`leading-[20px] ${lightTheme ? 'text-brand-color-main' : 'text-white'}`}
        >
          <Logo width={92} />
        </Link>
      </Box>
      <IconButton
        classes={{ root: classes.menuIcon }}
        aria-label="menu"
        size="s"
        onClick={handleMenuIconClick}
      >
        <Box>
          <Box
            className={`${classes.topLine} ${burgerTopLineClassName}`}
            mb={0.75}
            width={18}
            height={2}
            bgcolor={menuIconColor}
          />
          <Box mb={0.75} width={18} height={2} bgcolor={menuIconColor} />
          <Box
            className={`${classes.bottomLine} ${burgerBottomLineClassName}`}
            width={18}
            height={2}
            bgcolor={menuIconColor}
          />
        </Box>
      </IconButton>
      <AppBarDrawer
        letsConnectCard={letsConnectCard}
        open={isDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      >
        <ul className="">
          {menuItems.map((item, index) => {
            return item.children ? (
              <li key={`menu-item_${index}`} className="py-5">
                <details
                  className="[&_summary]:open:text-latte [&_svg]:open:-rotate-180"
                  name="with-sub-nav"
                >
                  <summary className="flex w-full list-none items-center justify-between text-white [&::-webkit-details-marker]:hidden">
                    <p className="block text-arc-base font-bold text-current">
                      {item.text}
                    </p>
                    <span className="text-current">
                      <ChevronDown />
                    </span>
                  </summary>
                  <div>
                    {item.description && (
                      <>
                        <span className="border-[rgb(128, 128, 128, .4)] block py-4 text-arc-base text-white">
                          {item.description}
                        </span>
                        <hr className="full-width opacity-20" />
                      </>
                    )}
                    <ul className="mt-2 flex flex-col gap-2 text-white">
                      {item.children.map((menuItem, idx) => (
                        <li
                          key={`menu_${idx}`}
                          className="py-2 hover:text-latte focus:text-latte"
                        >
                          <Link
                            href={`${menuItem.link}`}
                            className="text-arc-base leading-[20px] no-underline o-2"
                            target={menuItem.target}
                            rel={menuItem.rel}
                          >
                            <p className="font-bold">{menuItem.text}</p>
                            {menuItem.tagline && (
                              <span className="block text-arc-sm">
                                {menuItem.tagline}
                              </span>
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </details>
              </li>
            ) : (
              <li
                key={`menu-item_${index}`}
                className="w-full py-6 hover:text-latte focus:text-latte"
              >
                <Link
                  href={`${item.link}`}
                  className="block text-arc-base font-bold text-white no-underline"
                >
                  {item.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </AppBarDrawer>
    </Box>
  );
};

export default AppBarMobile;
