import React, { useState } from 'react';
import Box from '../Box';
import Link from 'next/link';
import { Logo, ChevronDown } from '../Icons';
import Typography from '../Typography';
import useStyles from './useStyles';
import Button from '../Button';
import Menu from '../Menu';
import MuiMenuItem from '../MenuItem';

interface AppBarDesktopProps {
  menuItems: MenuItem[];
  slogan: string;
  // eslint-disable-next-line react/require-default-props
  lightTheme?: boolean;
}

interface MenuItemElement {
  target?: string;
  rel?: string;
  text: string;
  link: string;
  tagline?: string;
}
export interface MenuItem {
  text: string;
  link?: string;
  description?: string;
  children?: MenuItemElement[];
}

const AppBarDesktop: React.FC<AppBarDesktopProps> = ({
  menuItems,
  slogan,
  lightTheme = false,
}) => {
  // -----------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const handleMenuClose = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      const relatedTarget = event.relatedTarget as HTMLElement;
      if (
        anchorEl &&
        relatedTarget &&
        anchorEl instanceof HTMLElement &&
        relatedTarget instanceof HTMLElement &&
        (anchorEl?.contains(relatedTarget) ||
          relatedTarget.closest('.MuiMenu-list'))
      ) {
        return;
      }
    }
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };

  const classes = useStyles({ lightTheme });

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Link
          href="/"
          className={`leading-[20px] ${lightTheme ? 'text-brand-color-main' : 'text-white'}`}
          title="Software Development &amp; Team Augmentation Partner Philippines"
        >
          <Logo width={119} />
        </Link>

        <Box ml={6}>
          <Typography className={classes.slogan} fontWeight={700}>
            {slogan}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {menuItems.map((item, index) => {
          return item.children ? (
            // eslint-disable-next-line react/no-array-index-key
            <Box ml={7} key={`item_${index}`}>
              <Button
                classes={{
                  root: classes.menuButton,
                }}
                className={`[&>.MuiButton-endIcon]:transition-transform ${openMenuIndex === index ? '!text-latte [&>.MuiButton-endIcon]:rotate-180' : `${lightTheme ? '!text-brand-color-main' : '!text-white'}`}`}
                disableRipple
                variant="text"
                endIcon={<ChevronDown />}
                onMouseEnter={(event) => handleMenuOpen(event, index)}
                onMouseLeave={(event) => {
                  handleMenuClose(event);
                }}
              >
                {item.text}
              </Button>
              <Menu
                open={openMenuIndex === index}
                className={classes.popover}
                PaperProps={{
                  sx: {
                    maxWidth: '450px',
                  },
                }}
                classes={{
                  paper: classes.popoverContent,
                  list: classes.menuList,
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={0}
                disableAutoFocusItem
                keepMounted
                MenuListProps={{
                  onMouseLeave: (event) => {
                    handleMenuClose(event);
                  },
                }}
              >
                {item.description && (
                  <span className="border-[rgb(128, 128, 128, .4)] block border-b px-8 py-4 text-arc-lg">
                    {item.description}
                  </span>
                )}
                {item.children.map((menuItem, idx) => (
                  <MuiMenuItem
                    className={`${classes.menuListItem}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`menu-item_${idx}`}
                  >
                    <Link
                      href={`${menuItem.link}`}
                      key={`${menuItem.link}`}
                      className="block w-full px-4 py-4 no-underline o-2"
                      target={menuItem.target}
                      rel={menuItem.rel}
                    >
                      <strong>{menuItem.text}</strong>
                      {menuItem.tagline && (
                        <span className="block text-arc-sm text-custom-gray-dark">
                          {menuItem.tagline}
                        </span>
                      )}
                    </Link>
                  </MuiMenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            // eslint-disable-next-line react/no-array-index-key
            <Box ml={7} key={`menu-item_${index}`}>
              <Link
                href={`${item.link}`}
                className={`relative top-0 py-8 text-arc-xl font-bold leading-6 no-underline hover:text-latte ${lightTheme ? 'text-brand-color-main' : 'text-white'}`}
              >
                {item.text}
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AppBarDesktop;
